import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import generateLink from '../../utils/generateLink'

import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import SectionDoubleColumn from '../../components/SectionDoubleColumn'
import SideCol from '../../components/SectionDoubleColumn/SideCol'
import ParagraphBuilder from '../../components/ParagraphBuilder'

const MoldMaking = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          } 
          pagesData {
            services {
              sideSection {
                firstBlockLinks {
                  text {
                    de
                    en
                    it
                  }
                  path
                }
                secondBlock {
                  de
                  en
                  it
                }
              }
              pageTitle {
                de
                en
                it
              }
              subpages {
                moldMaking {
                  pageTitle {
                    de
                    en
                    it
                  }
                  paragraphs {
                    data {
                      de
                      en
                      it
                    }
                    type
                  }
                  subtitle {
                    de
                    en
                    it
                  }
                }
              }
            }
          }
        }
      }
    }
      `
  )
  const {
    pageTitle,
    paragraphs,
    subtitle
  } = site.siteMetadata.pagesData.services.subpages.moldMaking

  const sideSection = site.siteMetadata.pagesData.services.sideSection
  console.log(paragraphs)
  const mainCol = (
    <div key='main_col'>
      <h2 className='text-3xl underline font-bold mb-16'>{subtitle[langKey]}</h2>
      <ParagraphBuilder paragraphs={paragraphs} langKey={langKey} key='pb' />
    </div>
  )

  const linksForSide = sideSection.firstBlockLinks.map((el) => ({ path: generateLink(langKey, '/services/' + el.path), text: el.text[langKey], selected: el.path === 'mold_making' }))
  const sideCol = <SideCol links={linksForSide} textBlock={sideSection.secondBlock[langKey]} />

  return (
    <>
      <Seo title={pageTitle[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={pageTitle[langKey]} />
      <SectionDoubleColumn mainCol={mainCol} sideCol={sideCol} />
    </>
  )
}

export default MoldMaking
